import React from 'react';
import type { ConsentValues } from 'types/main';
export type SessionContextType = {
  csrfToken?: string;
  honeypot?: string;
  spinner?: string;
  consent?: ConsentValues | null;
  [propName: string]: any;
};
const SessionContext = React.createContext({} as SessionContextType);
export const SessionContextProvider = SessionContext.Provider;
export default SessionContext;
